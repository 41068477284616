import React, { useState } from "react";
import "../styles/Dashboard.css";
import InstallTab from "./tabs/InstallTab";
import TokenTab from "./tabs/TokenTab";
import TunnelsTab from "./tabs/TunnelsTab";

const tabs = [
  "УСТАНОВКА",
  "ТОКЕН",
  "ТУННЕЛИ",
  "ДОМЕНЫ",
  "TCP ПОРТЫ",
  "ПОДПИСКА",
  "МАСТЕРКЛЮЧ",
  "ПРОФИЛЬ",
];

export default function Dashboard({ user, onLogout }) {
  const [activeTab, setActiveTab] = useState("УСТАНОВКА");

  return (
    <div className="dashboard-wrapper">
      <aside className="sidebar">
        <h2 className="sidebar-title">TunnelNinja</h2>
        <nav className="tab-menu">
          {tabs.map((tab) => (
            <div
              key={tab}
              className={`tab-item ${activeTab === tab ? "active" : ""}`}
              onClick={() => setActiveTab(tab)}
            >
              {tab}
            </div>
          ))}
        </nav>
        <div className="sidebar-footer">
          <p>{user.email}</p>
          <button className="logout-button" onClick={onLogout}>
            Выйти
          </button>
        </div>
      </aside>

      <main className="tab-content">
        <h1>{activeTab}</h1>

        {activeTab === "УСТАНОВКА" && <InstallTab token={user.token} />}
        {activeTab === "ТОКЕН" && <TokenTab token={user.token} />}
        {activeTab === "ТУННЕЛИ" && <TunnelsTab />}
        {!["УСТАНОВКА", "ТОКЕН", "ТУННЕЛИ"].includes(activeTab) && (
          <p>Контент для: {activeTab}</p>
        )}
      </main>

      <div
        className="help-button"
        onClick={() => alert("Напиши нам на support@tunnelninja.com")}
      >
        ?
      </div>
    </div>
  );
}
