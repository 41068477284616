import React from "react";
import { FiCopy, FiRefreshCw, FiPlus } from "react-icons/fi";
import "./TokenTab.css";

const TokenTab = ({ token }) => {
  const copyToken = () => {
    navigator.clipboard.writeText(token);
    alert("Токен скопирован!");
  };

  const generateNewToken = () => {
    alert("Функция генерации нового токена ещё не реализована.");
    // Здесь можно будет сделать fetch на /api/regenerate-token
  };

  return (
    <div className="token-tab">
      <h2>Ваш токен для tuna</h2>
      <div className="token-display">
        <code>{token}</code>
        <button className="copy-btn" onClick={copyToken}>
          <FiCopy />
        </button>
      </div>
      <div className="token-buttons">
        <button onClick={generateNewToken}>
          <FiRefreshCw /> Сгенерировать новый
        </button>
        <button>
          <FiPlus /> Создать дополнительный
        </button>
      </div>
    </div>
  );
};

export default TokenTab;
