import React from "react";
import { FiBookOpen, FiPlus } from "react-icons/fi";
import "./TunnelsTab.css";

const TunnelsTab = () => {
  const tunnels = []; // сюда позже можно будет подгружать реальные данные

  return (
    <div className="tunnels-tab">
      <div className="tunnels-header">
        <div>
          <h2>Туннели</h2>
          <p>Список публичных точек подключения к локальным ресурсам.</p>
        </div>
        <div className="tunnels-actions">
          <button className="doc-btn"><FiBookOpen /></button>
          <button className="create-btn"><FiPlus /> Создать туннель</button>
        </div>
      </div>

      {tunnels.length === 0 ? (
        <div className="tunnels-empty">Тут пока ничего нет</div>
      ) : (
        <div> {/* тут будет список туннелей */} </div>
      )}
    </div>
  );
};

export default TunnelsTab;
