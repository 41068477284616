import React, { useState } from "react";
import "./InstallTab.css";

const platforms = {
  Windows: {
    cmd: `winget install --id yuccastream.tunnelninja ; powershell`,
  },
  macOS: {
    cmd: `brew install tunnelninja`,
  },
  Linux: {
    cmd: `curl -fsSL https://tunnelninja.com/install.sh | bash`,
  },
  FreeBSD: {
    cmd: `pkg install tunnelninja`,
  },
  Docker: {
    cmd: `docker run tunnelninja/tunnelninja`,
  },
};

const InstallTab = ({ token }) => {
  const [selected, setSelected] = useState("Windows");

  return (
    <div className="install-tab">
      {/* Шаг 1 */}
      <div className="step">
        <div className="step-number">1</div>
        <div className="step-content">
          <h2>Скачайте клиент</h2>
          <div className="platform-tabs">
            {Object.keys(platforms).map((platform) => (
              <button
                key={platform}
                className={selected === platform ? "active" : ""}
                onClick={() => setSelected(platform)}
              >
                {platform}
              </button>
            ))}
          </div>
          <p>Выполните команду:</p>
          <pre>
            <code>{platforms[selected].cmd}</code>
          </pre>
        </div>
      </div>

      {/* Шаг 2 */}
      <div className="step">
        <div className="step-number">2</div>
        <div className="step-content">
          <h2>Установите токен</h2>
          <p>
            Эта команда позволит сохранить токен, чтобы не указывать его в
            аргументах запуска постоянно:
          </p>
          <pre>
            <code>
              tunnelninja config save-token {token || "<токен_не_загружен>"}
            </code>
          </pre>
        </div>
      </div>

      {/* Шаг 3 */}
      <div className="step">
        <div className="step-number">3</div>
        <div className="step-content">
          <h2>Пользуйтесь</h2>
          <p>
            Всё готово для начала работы. Запустите tunnelninja с передачей
            порта и делитесь ссылкой:
          </p>
          <pre>
            <code>tunnelninja http 8080</code>
          </pre>
        </div>
      </div>
    </div>
  );
};

export default InstallTab;
