import React, { useState, useEffect } from "react";
import Login from "./Login/Login";
import Dashboard from "./Dashboard/Dashboard";

function App() {
  const [user, setUser] = useState(null);

  // Загружаем пользователя из localStorage при старте
  useEffect(() => {
    const stored = localStorage.getItem("user");
    if (stored) {
      try {
        setUser(JSON.parse(stored));
      } catch (e) {
        console.error("Ошибка при чтении user из localStorage:", e);
      }
    }
  }, []);

  const handleLogin = (userData) => {
    setUser(userData);
    localStorage.setItem("user", JSON.stringify(userData));
  };

  const handleLogout = () => {
    setUser(null);
    localStorage.removeItem("user");
  };

  return (
    <>
      {user ? (
        <Dashboard user={user} onLogout={handleLogout} />
      ) : (
        <Login onLogin={handleLogin} />
      )}
    </>
  );
}

export default App;
