import React from "react";
import { GoogleOAuthProvider, GoogleLogin } from "@react-oauth/google";
import { jwtDecode } from "jwt-decode";
import "../styles/Login.css";

export default function Login({ onLogin }) {
  const handleSuccess = async (credentialResponse) => {
    try {
      const decoded = jwtDecode(credentialResponse.credential);

      const res = await fetch("https://tunnelninja.com/api/auth", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ email: decoded.email }),
      });

      if (!res.ok) {
        const errorText = await res.text();
        throw new Error("Ошибка сервера: " + errorText);
      }

      const data = await res.json();

      // Сохраняем и передаём в App
      onLogin({ ...decoded, token: data.token });
    } catch (error) {
      console.error("Ошибка при получении токена:", error);
      alert("Ошибка при получении токена: " + error.message);
    }
  };

  return (
    <GoogleOAuthProvider clientId="337690780653-fb1eaeaml4hauuj0hnc7d6dm09krv1gc.apps.googleusercontent.com">
      <div className="login-page">
        <div className="login-left">
          <h1 className="login-title">TunnelNinja</h1>
          <p className="login-sub">Вход через Google</p>
          <GoogleLogin
            onSuccess={handleSuccess}
            onError={() => alert("Ошибка входа")}
          />
        </div>
        <div className="login-right">
          <h2>Почему TunnelNinja?</h2>
          <ul>
            <li>⚡ Молниеносная скорость соединения</li>
            <li>🔐 Безопасное подключение</li>
            <li>🌍 Поддержка разных стран</li>
            <li>🧠 Умная маршрутизация трафика</li>
            <li>🆓 Бесплатный тестовый период</li>
          </ul>
        </div>
      </div>
    </GoogleOAuthProvider>
  );
}
